import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Img from "react-image";
import { SliderBasic } from "inno-components";
import { StyleSheet, css } from "aphrodite";
import cn from "classnames";

const styles = StyleSheet.create({
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1em",
    margin: "0.5em"
  }
});

const responsive = [
  { breakpoint: 768, settings: { slidesToShow: 1 } },
  { breakpoint: 1024, settings: { slidesToShow: 2 } }
];
// '2', '3', '4', '5'
const references = ["1", "2", "3", "4"];

const HomeReferences = () => {
  const References = references.map(reference => (
    <div
      className={cn("item", css(styles.item))}
      key={"reference-" + reference}
    >
      <Img src={"/assets/img/clients/" + reference + ".png"} alt="Owl Image" />
    </div>
  ));

  return (
    <div id="clients" style={{ marginTop: "0" }}>
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 100 102"
        height="100"
        width="100%"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0 L50 100 L100 0 Z" fill="#fff" stroke="#fff" />
      </svg>
      <div className="container">
        <div className="row">
          <div className="col-md-12" />
        </div>
      </div>
    </div>
  );
};

/*
  <SliderBasic
            slidesToShow={3}
            slidesToScroll={1}
            responsive={responsive}>
            {References}
            </SliderBasic>
            */

export default HomeReferences;
