import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import GastbyImg from 'gatsby-image'
import Img from 'react-image'
import Helmet from 'react-helmet'

import PrimaryLayout from './layouts/PrimaryLayout'
import Contact from './layouts/Contact'
import Button from '@material-ui/core/Button'
import HomeNews from './HomeNews'
import HomeReferences from './HomeReferences'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'
import pagesInfos from '../config/i18n/pagesInfos'

import Link from './Link'
import TennisIcon from './icons/Tennis'
import TennisIcon1 from './icons/Tennis1'

import { withNamespaces } from 'react-i18next'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
})

const IndexPage = ({ t }) => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  //console.log('t: ', i18next.t('head.title'))
  return (
    <StaticQuery
      query={graphql`
        query AccueilImageQuery {
          header: file(relativePath: { eq: "header.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 6000, maxHeight: 4000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <PrimaryLayout>
            <Helmet
              title={t('le_club.meta.title')}
              meta={[
                {
                  name: 'description',
                  content:t('le_club.meta.description')
                },
                { name: 'charset', content: 'UTF-8' }
              ]}
            />
            <Scroll.Element name="accueil" id="accueil">
              <section className="box-intro" style={{ position: 'relative' }}>
                {

                <GastbyImg
                  fluid={data.header.childImageSharp.fluid}
                  className={css(styles.image)}
                />
                  
                }
                <div
                  className="table-cell"
                  style={{ paddingTop: '35em', position: 'relative' }}
                >
                  <h1 style={{ color:"white",textShadow:"5px 5px 10px #002337"}}>
                    {t('le_club.accueil.title1')}
                  </h1>

                  <h2 style={{ color:"white",textShadow:"5px 5px 10px #002337"}}>
                    {t('le_club.accueil.title2')}
                  </h2>
                  
                  <h3 className="box-headline letters rotate-2" style={{ color:"white",textShadow:"5px 5px 10px #002337"}}>
                    <span className="box-words-wrapper">
                      <b className="is-visible">{t('le_club.accueil.sous_titre1')}</b>
                      <b>{t('le_club.accueil.sous_titre2')}</b>
                    </span>
                  </h3>
                </div>
              </section>
              <section
                id="le_club"
                className="team mbr-box mbr-section mbr-section--relative"
              >
                <svg
                  preserveAspectRatio="none"
                  viewBox="0 0 100 102"
                  height="100"
                  width="100%"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 0 L50 100 L100 0 Z"
                    fill="#082d5B"
                    stroke="#082d5B"
                  />
                </svg>
                <div className="container">
                  <div className="col-md-8 col-md-offset-2 col-sm-12">
                    <div
                      className="row center mb-100"
                      style={{ marginTop: '5em' }}
                    >
                      <div className="section-title-parralax">
                        <div className="process-numbers">
                          <TennisIcon />
                        </div>
                        <h2>
                          {t('le_club.accueil.presentation.title')}
                        </h2>
                        <p className="module-subtitle">
                          {t('le_club.accueil.presentation.paragraphe1')}
                        </p>
                        <p className="module-subtitle">
                          {t('le_club.accueil.presentation.paragraphe2')}
                        </p>
                        <p className="module-subtitle">
                          {t('le_club.accueil.presentation.paragraphe3')}
                        </p>
                        <p className="module-subtitle">
                          {t('le_club.accueil.presentation.paragraphe4')}
                        </p>
                        <p className="module-subtitle">
                          {t('le_club.accueil.presentation.paragraphe5')}
                        </p>
                        <div className="module-subtitle">
                          <div 
                            className="button-custom-container"
                            style={{textAlign: 'center',height:"401px"}}
                          >
                            <Link
                              page={pagesInfos.ComponentPaddel}
                              className="button-custom"
                              style={{ width: '100%', backgroundImage:"url(/assets/img/padel.png)",height:"400px", backgroundSize:"cover",backgroundRepeat:"no-repeat"}}
                            >
                              <span
                              style={{ position:'relative',top:"40%",color:"white",fontSize:"25px",textShadow:"5px 5px 10px black"}}
                              >
                              {t('le_club.accueil.presentation.lienP')}
                              </span>
                            </Link>
                          </div>

                          <div 
                            className="button-custom-container"
                            style={{ textAlign: 'center',height:"401px"}}
                          >
                            <Link
                              page={pagesInfos.ComponentTennis}
                              className="button-custom"
                              style={{ width: '100%', backgroundImage:"url(/assets/img/TENNIS_Btn.jpeg)",height:"400px", backgroundSize:"cover",backgroundRepeat:"no-repeat"}}
                            >
                              <span
                              style={{ position:'relative',top:"40%",color:"white",fontSize:"25px",textShadow:"5px 5px 10px black"}}
                              >
                              {t('le_club.accueil.presentation.lienT')}
                              </span>
                            </Link>
                          </div>

                          <div 
                            className="button-custom-container"
                            style={{ textAlign: 'center', height:"401px"}}
                          >
                            <Link
                              page={pagesInfos.ComponentTouchTennis}
                              className="button-custom"
                              style={{ width: '100%', backgroundImage:"url(/assets/img/touchtennis.jpeg)",height:"400px", backgroundSize:"cover",backgroundRepeat:"no-repeat" }}
                            >
                              <span
                              style={{ position:'relative',top:"40%",color:"white",fontSize:"25px",textShadow:"5px 5px 10px black"}}
                              >
                              {t('le_club.accueil.presentation.lienTT')}
                              </span>
                            </Link>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-separator hidden-xs" />
              </section>
            </Scroll.Element>

            <Scroll.Element name="ventes" id="ventes">
                { 
                  //{'className="features mbr-box mbr-section mbr-section--relative"' }
                }
              <div
                id="features"
                
                className="features mt-100 mb-100 mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted mbr-parallax-background"
                
                style={{
                  backgroundImage:
                    'url(/assets/img/photos/1.jpg)'
                }}
              >
                <div className="container">
                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="section-title-parralax">
                          <div className="process-numbers">
                            {' '}
                            <TennisIcon1 />
                          </div>
                        <h2
                          style={{color:"white",textShadow:"5px 5px 10px black"}}
                        >{t('le_club.ventes.title')}</h2>
                        <p className="module-subtitle" style={{color:"white",textShadow:"5px 5px 10px black"}}>
                          {t('le_club.ventes.description')}
                          <ul >
                            <li style={{color:"white",textShadow:"5px 5px 10px black"}}>{t('le_club.ventes.description1')}</li>
                            <li style={{color:"white",textShadow:"5px 5px 10px black"}}>{t('le_club.ventes.description2')}</li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>

                  
                </div>
              </div>
            </Scroll.Element>

            <Scroll.Element name="courspadel" id="courspadel">
                { 
                  //{'className="features mbr-box mbr-section mbr-section--relative"' }
                }
              <div
                id="features"
                
                className="features mt-100 mb-100 mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted mbr-parallax-background"
                
                style={{
                  backgroundImage:
                    'url(/assets/img/photos/6.jpg)'
                }}
              >
                <div className="container">
                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="section-title-parralax">
                          <div className="process-numbers">
                            {' '}
                            <TennisIcon1 />
                          </div>
                        <h2
                          style={{color:"white",textShadow:"5px 5px 10px black"}}
                        >{t('le_club.courspadel.title')}</h2>
                        <p className="module-subtitle" style={{color:"white",textShadow:"5px 5px 10px black"}}>
                          {t('le_club.courspadel.description')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="button-custom-container">
                        <Link
                          page={pagesInfos.ComponentPhotos}
                          className="button-custom-white"
                          style={{textShadow:"5px 5px 10px black"}}
                        >
                          {t('le_club.courspadel.bouttonText')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll.Element>

            <Scroll.Element name="courstennis" id="courstennis">
                { 
                  //{'className="features mbr-box mbr-section mbr-section--relative"' }
                }
              <div
                id="features"
                
                className="features mt-100 mb-100 mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted mbr-parallax-background"
                
                style={{
                  backgroundImage:
                    'url(/assets/img/photos/8.jpg)'
                }}
              >
                <div className="container">
                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="section-title-parralax">
                          <div className="process-numbers">
                            {' '}
                            <TennisIcon1 />
                          </div>
                        <h2
                          style={{color:"white",textShadow:"5px 5px 10px black"}}
                        >{t('le_club.courstennis.title')}</h2>
                        <p className="module-subtitle" style={{color:"white",textShadow:"5px 5px 10px black"}}>
                          {t('le_club.courstennis.description')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="button-custom-container">
                        <Link
                          page={pagesInfos.ComponentPhotos}
                          className="button-custom-white"
                          style={{textShadow:"5px 5px 10px black"}}
                        >
                          {t('le_club.courstennis.bouttonText')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll.Element>

            <Scroll.Element name="photos" id="photos">
                { 
                  //{'className="features mbr-box mbr-section mbr-section--relative"' }
                }
              <div
                id="features"
                
                className="features mt-100 mb-100 mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted mbr-parallax-background"
                
                style={{
                  backgroundImage:
                    'url(/assets/img/photos/5.jpg)'
                }}
              >
                <div className="container">
                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="section-title-parralax">
                          <div className="process-numbers">
                            {' '}
                            <TennisIcon1 />
                          </div>
                        <h2
                          style={{color:"white",textShadow:"5px 5px 10px black"}}
                        >{t('le_club.photos.title')}</h2>
                        <p className="module-subtitle" style={{color:"white",textShadow:"5px 5px 10px black"}}>
                          {t('le_club.photos.description')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="button-custom-container">
                        <Link
                          page={pagesInfos.ComponentPhotos}
                          className="button-custom-white"
                          style={{textShadow:"5px 5px 10px black"}}
                        >
                          {t('le_club.photos.bouttonText')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll.Element>

            <Scroll.Element name="events" id="events">
                { 
                  //{'className="features mbr-box mbr-section mbr-section--relative"' }
                }
              <div
                id="features"
                
                className="features mt-100 mb-100 mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted mbr-parallax-background"
                
                style={{
                  backgroundImage:
                    'url(/assets/img/photos/3.jpg)'
                }}
              >
                <div className="container">
                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="section-title-parralax">
                          <div className="process-numbers">
                            {' '}
                            <TennisIcon1 />
                          </div>
                        <h2
                          style={{color:"white",textShadow:"5px 5px 10px black"}}
                        >{t('le_club.events.title')}</h2>
                        <p className="module-subtitle" style={{color:"white",textShadow:"5px 5px 10px black"}}>
                          {t('le_club.events.description')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="button-custom-container">
                        <Link
                          page={pagesInfos.ComponentEvents}
                          className="button-custom-white"
                          style={{textShadow:"5px 5px 10px black"}}
                        >
                          {t('le_club.events.bouttonText')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll.Element>

            <Scroll.Element name="restaurant" id="restaurant">
                { 
                  //{'className="features mbr-box mbr-section mbr-section--relative"' }
                }
              <div
                id="features"
                
                className="features mt-100 mb-100 mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted mbr-parallax-background"
                
                style={{
                  backgroundImage:
                    'url(/assets/img/bar-restaurant.jpeg)'
                }}
              >
                <div className="container">
                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="section-title-parralax">
                          <div className="process-numbers">
                            {' '}
                            <TennisIcon1 />
                          </div>
                        <h2
                          style={{color:"white",textShadow:"5px 5px 10px black"}}
                        >{t('le_club.restaurant.title')}</h2>
                        <p className="module-subtitle" style={{color:"white",textShadow:"5px 5px 10px black"}}>
                          {t('le_club.restaurant.description')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row center">
                    <div className="col-md-8 col-md-offset-2 col-sm-12">
                      <div className="button-custom-container">
                        <Link
                          page={pagesInfos.ComponentRestaurant}
                          className="button-custom-white"
                          style={{textShadow:"5px 5px 10px black"}}
                        >
                          {t('le_club.restaurant.bouttonText')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll.Element>
            <Scroll.Element name="jeuxdecartes" id="jeuxdecartes">
              <section className="portfolio">
                <div
                  className="hidden-xs"
                  style={{
                    borderColor: 'transparent transparent #fcf3d3 transparent',
                    top: 'auto',
                    bottom: 0
                  }}
                  
                />
                <div 
                className="container mt-100 mb-100 mbr-box mbr-section mbr-section--relative mbr-section--fixed-size mbr-section--bg-adapted mbr-parallax-background"
                
                style={{
                  backgroundImage:
                    'url(/assets/img/jeux-de-cartes.jpeg)'
                }}
                >
                  <div className="col-md-8 col-md-offset-2 col-sm-12">
                    <div
                      className="row center"
                      style={{ margin: '5em 0 0 0' }}
                    >
                      <div className="section-title mb-100">
                        <div className="process-numbers">
                          <TennisIcon1 />
                        </div>
                        <h2 style={{color:"white",textShadow:"5px 5px 10px black"}}>{t('le_club.jeuxdecartes.title')}</h2>
                        <p className="module-subtitle" style={{color:"white",textShadow:"5px 5px 10px black"}}>
                          {t('le_club.jeuxdecartes.description')}
                        </p>
                        <div className="button-custom-container">
                          <Link
                            page={pagesInfos.ComponentJeuxDeCartes}
                            className="button-custom-white"
                            style={{textShadow:"5px 5px 10px black"}}
                          >
                            {t('le_club.jeuxdecartes.bouttonText')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Scroll.Element>
            
            <HomeReferences />

            <Scroll.Element name="contact_" id="contact_">
              <section
                id="contact_"
                className="contact mbr-box mbr-section mbr-section--relative mbr-section--bg-adapted"
              >
                <div className="container">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <h4>{t('le_club.contact.title1')}</h4>
                      <p className="libre-text mt-50">
                        {t('le_club.contact.L1')}<br></br>
                        {t('le_club.contact.M1')}<br></br>
                        {t('le_club.contact.Me1')}<br></br>
                        {t('le_club.contact.J1')}<br></br>
                        {t('le_club.contact.V1')}<br></br>
                        {t('le_club.contact.S1')}<br></br>
                        {t('le_club.contact.D1')}<br></br>
                      </p>
                      <br></br>
                      <br></br>
                      <h4>{t('le_club.contact.title2')}</h4>
                      <p className="libre-text mt-50">
                        {t('le_club.contact.description2')}
                        <br></br>
                        <br></br>
                        {t('le_club.contact.L2')}<br></br>
                        {t('le_club.contact.M2')}<br></br>
                        {t('le_club.contact.Me2')}<br></br>
                        {t('le_club.contact.J2')}<br></br>
                        {t('le_club.contact.V2')}<br></br>
                        {t('le_club.contact.S2')}<br></br>
                        {t('le_club.contact.D2')}<br></br>
                      </p>

                    </div>
                  </div>

                  <div className=" col-md-offset-1 col-md-5 col-sm-12" id="contact">
                    <div className="row center">
                      <div className="newsletter">
                        <h4>CONTACT</h4>
                        <p className="libre-text mb-50">
                          Des questions ? N’hésitez pas.
                        </p>
                        <Contact/>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Scroll.Element>
          </PrimaryLayout>
        )
      }}
    />
  )
}

export default withNamespaces('home')(IndexPage)
